import React from "react";
import FullScreenSection from "./FullScreenSection";
import {Heading } from "@chakra-ui/react";
import Card from "./Card";
import { useTranslation } from "react-i18next";

const projects = [
  {
    title: "Enigma Chat",
    description:
      "Enigma is a new chat for you and your friends. Developed with ReactNative and Java using SpringBoot and Google FireBase 🔥️. This chat was created and developed as OOP project. It contains modern design and user-expirience philosophy",
    getImageSrc: () => require("../images/Enigma.png"),
    link: "https://github.com/DieMon-dev/Enigma"
  },
  {
    title: "KoNaR Web Page",
    description:
      "Exciting team project. Modern design web page of Science Circle of Robotics KoNaR. Developed with React, StoryBook, Docker, Typescript and TailwindCSS",
    getImageSrc: () => require("../images/Konar.png"),
    link: "https://konar.pwr.edu.pl/"
  },
  {
    title: "Covid19 Statistic Monitoring App",
    description:
      "This application allows you to observe the Covid cases of diferent status along counties or over the world. Developed using React.js and Ajax for processing API requests",
    getImageSrc: () => require("../images/Covid.png"),
    link: "https://github.com/KytryshAndrii/CovidRateApp"
  },
  {
    title: "Video Convertation Web-App",
    description:
      "Convert-App is an application that allows you to convert many files in one folder to another extantion in easy and fast way. WEBAssebling and FFmpeg was used. ",
    getImageSrc: () => require("../images/Convertation.png"),
    link: "https://github.com/KytryshAndrii/Convert_App"
  },
];

const ProjectsSection = () => {

  const { t } = useTranslation();

  return (
    <FullScreenSection
      backgroundColor="#F8FAFC"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
      id="projects-section"
    >
      <Heading as="h1" color={"blackAlpha.600"}>
        {t('Featured Projects')}
      </Heading>
      <div className="grid lg:grid-cols-2 lg:grid-rows-2 grid-cols-1 grid-rows-4 gap-8">
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            link = {project.link}
          />
        ))}
      </div>
    </FullScreenSection>
  );
};

export default ProjectsSection;
