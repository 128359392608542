import {useState} from "react";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    setLoading(true);
    await wait(2000);
    emailjs.send('service_tvodajp', 'template_atqx2rp', data, {
        publicKey: '8usZlVrc0z7qDKGdc',
      }).then(
        () => {
          setResponse({
            type: 'success',
            message: t('Thanks for your submission ') + data.firstName + t(' I will get back to you shortly!'),
          })
        },() => {
          setResponse({
            type: 'error',
            message: t('Something went wrong, please try again later!'),
          })
        },);
    setLoading(false);
  }


  return { isLoading, response, submit };
}

export default useSubmit;
