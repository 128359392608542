import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: andriikytrysh@gmail.com",
    key:"#001",
  },
  {
    icon: faGithub,
    url: "https://github.com/KytryshAndrii",
    key:"#003",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/andrii-kytrysh",
    key:"#004",
  },
];

const links = [
  {
    text: "Projects",
    key:"#011",
    link:"projects"
  },
  {
    text: "Contact Me",
    key:"#012",
    link:"contactme"
  }
];


const Header = () => {

  const { t, i18n } =  useTranslation();
  const lang = i18n.language;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const languages=[
    {
      code: 'pl',
      clickFunction: () => changeLanguage('pl'),
      active: lang === 'pl'
    },
    {
      code: 'en',
      clickFunction: () => changeLanguage('en'),
      active: lang === 'en'
    }
  ]

  const scrollPosition = React.useRef(window.scrollY);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    if (currentPosition > scrollPosition.current) {
      document.getElementById('header').style.transform = `translateY(-200px)`;
    } else {
      document.getElementById('header').style.transform = `translateY(0px)`;
    }
    scrollPosition.current = currentPosition;
  };

  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box
      zIndex={"dropdown"}
      id="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      background="#F8FAFC"
    >
      <Box color="black" maxWidth="1280px" margin="0 auto">
        <HStack
          px={5}
          py={4}
          className="sm:justify-between justify-end"
          align={"baseline"}
        >
          <nav>
            {socials.map((item)=>(
              <a href={item.url} className="sm:p-4 p-1 caret-transparent" target="_blank" key={item.key}><FontAwesomeIcon className="sm:text-3xl noneiphone:text-lg iphone5:text-sm" icon={item.icon} /></a>
            ))}
          </nav>
          <nav>
            <HStack spacing={1} align={"baseline"}>
            {links.map((item)=>(
              <a className="md:p-4 sm:text-lg noneiphone:text-[18px] iphone5:text-[10px] p-1 font-bold hover:opacity-70 hover:cursor-pointer caret-transparent" onClick={handleClick(item.link)} key={item.key}>{t(item.text)}</a>
            ))}
            {languages.map((languageObj) => (
              <div lang={languageObj.code} onClick={languageObj.clickFunction}>
                <a rel="nofollow" className={`ml-2 font-bold ${languageObj.active ? "text-black" : "text-stone-400"} uppercase cursor-pointer lg:text-xl sm:text-base noneiphone:text-[20px] iphone5:text-[12px] transition-all duration-300 lg:ml-3 hover:text-red-700 caret-transparent mb-10`}>{languageObj.code}</a>
              </div>
            ))}
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
