import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
import ProjectsSection from "./components/ProjectsSection";
import ContactMeSection from "./components/ContactMeSection";
import Footer from "./components/Footer";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import { I18nextProvider } from 'react-i18next';
import i18n from './18n';

function App() {
  return (
    <ChakraProvider>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <AlertProvider>
        <main>
          <Header />
          <LandingSection />
          <ProjectsSection />
          <ContactMeSection />
          <Footer />
          <Alert />
        </main>
      </AlertProvider>
      </I18nextProvider>
    </ChakraProvider>
  );
}

export default App;
