import React, {useEffect} from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import FullScreenSection from "./FullScreenSection";
import useSubmit from "../hooks/useSubmit";
import { useAlertContext } from "../context/alertContext";
import { useTranslation } from "react-i18next";

const ContactMeSection = () => {

  const { t } = useTranslation();
  const { isLoading, response, submit } = useSubmit();
  const { onOpen } = useAlertContext();
  
  const formik = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      type: '',
      comment: ''
    },
    onSubmit: async (values, { resetForm }) => {
      await submit(values);
      resetForm();
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      type: Yup.string().required('Type is required'),
      comment: Yup.string().required('Comment is required').min(25, "The comment must contains at least 25 symbols")
    }),
  });

  useEffect(() => {
    if (response) {
      if (response.type === "success") {
        onOpen("success", response.message);
      } else if (response.type === "error") {
        onOpen("error", response.message);
      }
    }
  }, [response]);

  return (
    <FullScreenSection
      isDarkBackground
      backgroundColor="#512DA8"
      id="contactme-section"
      py={4}
      spacing={4}
    >
      <VStack w="100vw" p={{lg:56, md:32, base:4}} pt={{lg:6}} alignItems="flex-start">
        <Heading as="h1" fontSize={{md: "4xl", base:"3xl"}} pl={{sm:0, base:5}}>
          {t('Contact Me')}
        </Heading>
        <Box p={6} rounded="md" w="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName}>
                <FormLabel htmlFor="firstName">{t('Name')}</FormLabel>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  {...formik.getFieldProps('firstName')}
                  placeholder="Name Surname"
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                <FormLabel htmlFor="email">{t('Email Address')}</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps('email')}
                  placeholder="example@gmail.com"
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.type && formik.errors.type}>
                <FormLabel htmlFor="type">{t('Type of enquiry')}</FormLabel>
                <Select
                  id="type"
                  name="type"
                  bg="none"
                  {...formik.getFieldProps('type')}
                  placeholder="Choose the type of enquiry..."
                >
                  <option value="hireMe" className="text-black">{t('Freelance project proposal')}</option>
                  <option value="openSource" className="text-black">{t('Job proposal')}</option>
                  <option value="other" className="text-black">{t('Other')}</option>
                </Select>
                <FormErrorMessage>{formik.errors.type}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.comment && formik.errors.comment}>
                <FormLabel htmlFor="comment">{t('Your message')}</FormLabel>
                <Textarea
                  id="comment"
                  name="comment"
                  height={250}
                  {...formik.getFieldProps('comment')}
                  placeholder="Your comment must be here..."
                />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button type="submit" colorScheme="purple" width="full" isLoading={isLoading}>
                {t('Submit')}
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default ContactMeSection;

