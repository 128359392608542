import { Heading, HStack, Image, Text, VStack, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

const Card = ({ title, description, imageSrc, link }) => {

  const { t } = useTranslation();

  return (
    <Box
      bgColor={"#FFFFFF"}
      borderRadius={16}
      boxShadow='2xl'
      _hover={{
        transform: "scale(1.008)",
        transition: "all 0.25s ease-in-out"
      }}
    >
      <VStack>
        <Box  borderBottomRadius={100} boxShadow={'xl'}>      
          <Image src={imageSrc} borderRadius={16}  />
        </Box>  
        <Box p={3}>  
          <Heading textColor={"black"} size={"md"} p={1}>
            {t(title)}
          </Heading>
          <Text textColor={"black"} p={1} size={"sm"} fontWeight={"semi-bold"} w={{sm:5/6, base:10/11}}>
            {t(description)}
          </Text>
          <a href={link} target="_blank">
          <HStack color={"black"} justify={"start"} textAlign={"center"} p={1}> 
              <Text>{t('See more')}</Text>
              <Box pt={1}>
                <FontAwesomeIcon icon={faArrowRight}/>
              </Box>
          </HStack>
          </a>
        </Box>  
      </VStack>
    </Box>
  );
};

export default Card;
