import React from "react";
import FullScreenSection from "./FullScreenSection";
import image from '../images/Me.jpg'
import { useTranslation } from "react-i18next";

const greeting = "Hi, I am Andrii";
const bio1 = "Web Developer";

const LandingSection = () => {

  const { t } = useTranslation();

  return (
  <FullScreenSection
    id='landing-section'
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#F8FAFC"
    color="black"
  >
    <div className="flex lg:flex-row lg:gap-x-18 lg:gap-y-0 flex-col-reverse md:gap-y-36 sm:gap-y-24 gap-y-12 lg:justify-center lg:items-start items-center h-fit p-2 mt-14 sm:mt-0">
      <div className="lg:w-2/3 w-[93%] lg:justify-start iphone5:justify-center justify-center font-semibold  lg:text-left iphone5:text-center lg:text-4xl text-xl lg:p-10 md:p-7 sm:p-3">
        <h1 className="p-1 flex flex-row leading-10 font-bold lg:justify-start iphone5:justify-center justify-center md:text-5xl sm:text-4xl noneiphone:text-xl iphone5:text-sm">{t(greeting)}<a className="text-red-700 lg:ml-3 sm:ml-2 ml-1">{t('Kytrysh')}</a></h1>
        <h2 className="font-bold lg:p-2 p-1 sm:text-2xl iphone5:text-sm">{t(bio1)}</h2>
        <p className="sm:text-lg text-base lg:p-2 p-1">
          {t('I am a student of')} <a className=" font-bold text-red-700">{t('Wroclaw University of Science and Technology')}</a>. 
          {t(' I have been dealing with webdevelopment for 3 years. Creating, learning and developing is my true passion.')}<br/>
          {t('I have experience in')} <a className="font-bold">Git,</a> 
          <a className="font-bold"> Docker,</a>
          <a className="font-bold "> Type Script,</a><a className="font-bold"> Java</a>{t(' and other')}.
          <br/>{t('In search of a job and interesting offers!')}
        </p>
      </div>
      <div className="md:w-80 md:h-48 sm:w-64 sm:h-64 w-56 h-56">
        <img className="rounded-full" src={image}/>
      </div>
    </div>
  </FullScreenSection>

  )
}

export default LandingSection;
